// Tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// PrimeNg
@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeicons/primeicons.css";

// Theme
@import "@zaeper/primeng-sass-custom-themes/light-theme";

// Material Icons
@import 'material-icons/iconfont/material-icons.scss';
